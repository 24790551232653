@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --brown: #734D40;
    --lbrown: #ECD6BB;
    --lightbrown: #FBE9D1;
    --brown1: #563A30;
    --primary-font: "Poppins", sans-serif;
    --gap: 5rem;
}
html, #rock_web {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 62.5%;
}
body, #rock_web  {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--primary-font);
    background: var(--lightbrown);
    color: var(--brown);
}
a {
    cursor: pointer !important;
}
/* Gap */
.sec-padd-t {
    padding-top: var(--gap);
}
.sec-padd-b {
    padding-bottom: var(--gap);
}
.sec-padd {
    padding: var(--gap) 0 var(--gap) 0;
}
/* Gap end */
#rock_web h1 {
    font-size: 3.5rem;
}
#rock_web h2 {
    font-size: 3rem;
}
#rock_web h3 {
    font-size: 2.4rem;
}
#rock_web h4 {
    font-size: 2rem;
}
#rock_web h5 {
    font-size: 1.6rem;
}
#rock_web h6 {
    font-size: 1.4rem;
}
#rock_web h1,
#rock_web h2,
#rock_web h3,
#rock_web h4,
#rock_web h5,
#rock_web h6 {
    font-weight: 500;
    letter-spacing: 1px;
}
#rock_web a {
    text-decoration: none;
    font-size: 1.7rem;
    font-weight: 400;
    font-family: var(--primary-font);
}
img {
    max-width: 100%;
}
#rock_web  p{
    font-weight: 400;
}
/* header */
#rock_web header {
    position: sticky;
    top: 0;
    padding: 1rem 0;
    background: var(--lightbrown);
    z-index: 9;
}
#rock_web header .navbar-expand-lg{
    box-shadow: none;
}
#rock_web header .navbar-nav {
    gap: 1.5rem;
}
#rock_web header .navbar-nav .nav-link {
    color: var(--brown);
    padding: 0.5rem;
    font-size: 1.6rem;
    background: transparent !important;
}
#rock_web header .navbar-nav .nav-link.active {
    font-weight: 600;
    color: var(--brown);
}
#rock_web header .navbar-brand img {
    height: 60px;
}
#rock_web .heading1 {
    font-size: 4.2rem;
    font-weight: 200;
}
#rock_web .heading1 b {
    font-weight: 600;
}
#rock_web p {
    margin-bottom: 2rem;
}
#rock_web .logo1 {
    height: 70px;
}
#rock_web .heading-div {
    padding: 2rem;
    background: var(--lbrown);
    text-align: center;
}
#rock_web .heading-div h2 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 3.5rem;
}
#rock_web .space-b {
    margin-bottom: 4rem;
}
#rock_web .s-title {
    font-size: 1.8rem;
    font-weight: 700;
}
#rock_web .c-row {
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}
#rock_web .c-row li {
    display: flex;
    gap: 1rem;
    align-items: center;
}
#rock_web .c-row li span {
    font-weight: 500;
}
#rock_web .flex-between {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}
#rock_web .c-btn {
    font-weight: 500;
    padding: 1rem 3rem;
    border-radius: 8px;
    transition: all 0.3s;
}
#rock_web .brown-btn {
    background: var(--brown);
    color: var(--bs-white);
}
#rock_web .brown-btn:hover {
    background: var(--brown1);
}
#rock_web .m-heading {
    font-weight: 700;
}
#rock_web .icon-group {
    background: var(--brown);
    color: var(--lightbrown);
    margin-top: 3.5rem;
}
#rock_web .icon-with {
    display: flex;
    gap: 2rem;
    align-items: center;
    position: relative;
    padding: 1rem;
}
#rock_web .icon-with h3 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 2rem;
}
#rock_web .icon-with p {
    margin-bottom: 0;
    font-size: 1.6rem;
}
#rock_web .icon-with::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: var(--lightbrown);
    width: 1px;
}
#rock_web .icon-with img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
#rock_web .bg-pattern {
    background:url(../../images/rock/pattern.webp) center center / cover;
}
#rock_web ul li {
    padding-top: 1rem;
    font-weight: 400;
}
#rock_web .feature_sec p,
#rock_web .section-card p {
    font-style: italic;
    font-size: 1.4rem;
}
#rock_web .feature_sec .row+.row {
    padding-top: 2rem;
}
#rock_web .c-card {
    height: 100%;
    color: var(--lightbrown);
    background: var(--brown);
}
#rock_web .c-card .c-body {
    padding: 3rem 1rem 1rem;
}
#rock_web .c-card img {
    width: 100%;
}
#rock_web .c-card ul li {
    font-weight: 300;
}
#rock_web .c-card .c-body h3 {
    text-transform: uppercase;
    font-size: 2rem;
}
#rock_web .c-card ul h3 {
    padding-top: 2.4rem;
}
#rock_web .section-card p {
    margin-top: 2rem;
}
#rock_web .map-bg {
    background-color: var(--brown);
    background-image: url(../../images/rock/pattern2.webp);
}
#rock_web .flex-time {
    margin-top: 4rem;
    color: var(--lightbrown);
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
#rock_web .flex-time h3 {
    font-weight: 200;
    font-size: 6rem;
}
#rock_web .map-bg .flex-time+.flex-time {
    margin-top: 1rem;
}
#rock_web .right-side p {
    margin-bottom: 0;
    font-size: 1.4rem;
}
#rock_web .builder-sec img {
    float: right;
}
#rock_web .footer-form ul {
    list-style: none;
    padding-left: 0;
}
#rock_web .footer-form ul li {
    margin-top: 2rem;
    text-align: center;
}
#rock_web .footer-form form {
    padding-top: 1rem;
}
#rock_web .footer-form form input,
#rock_web .footer-form form textarea {
    background: #F9F2EA;
    padding: 1rem 2rem;
    width: 100%;
    border: none;
    outline: none;
    font-size: 2rem;
}
#rock_web .footer-form form input[type="radio"] {
    width: 10px;
    height: 10px;
    padding: 0;
    background: #ECD6BB;
    box-shadow: none;
}
#rock_web .footer-form form input[type="radio"]:checked {
    background: #68453A;
}
#rock_web .footer-form form label {
    margin-bottom: 1rem;
    padding-top: 1rem;
}
#rock_web .footer-form form button  {
    padding: 10px 40px 10px 40px;
    background: var(--brown);
    border-radius: 8px;
    color: var(--lightbrown);
    font-size: 2rem;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
#rock_web .footer-form form button:hover {
    background: var(--brown1);
}
#rock_web .sub-heading {
    font-weight: 500;
    font-size: 2rem;
}
#rock_web .c-heading3 {
    font-weight: 700;
}
#rock_web .footer-info {
    background: var(--brown);
    color: var(--lightbrown);
    position: relative;
    padding: 3rem 5rem;
    padding-bottom: 1rem;
}
#rock_web .footer-info img:first-child,
#rock_web .footer-info img:last-child {
    position: absolute;
    left: 0;
    bottom: 0;
}
#rock_web .footer-info img:last-child {
    right: 0;
    left: auto;
}
#rock_web .info-details h2 {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 6px;
}
#rock_web .info-details h3,
.info-details p,
#rock_web .footer-info h3 {
    font-size: 1.4rem;
    font-weight: 400;
}
#rock_web .info-details .col-md-4 img {
    position: relative;
}
#rock_web .group-2 {
    text-align: right;
}
#rock_web .group-m {
    text-align: center;
}
#rock_web .group-m h3 {
    margin-top: 1rem;
}
#rock_web .m-show {
    display: none;
}
@media (max-width: 1400px) {
    #rock_web .footer-info img:first-child,
    #rock_web .footer-info img:last-child {
        max-width: 220px;
    }
    #rock_web .group-m img {
        max-width: 150px !important;
    }
}
@media (max-width: 991px) {
    #rock_web .navbar-expand-lg #website-nav {
        background: var(--lbrown);
        box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
        -moz-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
        -webkit-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
        padding: 2rem;
        z-index: 999;
        position: fixed;
        width: 70%;
        left: 0;
        top: 0%;
        height: 100% !important;
        transition: 0s ease-in-out !important;
        transition: 0s ease !important;
        transform: translateX(-35ch);
        transition-property: transform !important;
        transition-duration: 0.5s !important;
        transition-delay: 0.05s !important;
    }
    #rock_web .navbar-expand-lg .navbar-collapse.show {
        transform: translateX(0ch) !important;
    }
    #rock_web header .navbar-nav {
        gap: 0.5rem;
    }
    #rock_web .navbar-toggler-icon {
        width: 2.5em;
        height: 2.5em;
        box-shadow: none !important;
        background-image: var(--bs-navbar-toggler-icon-bg);
    }
}
@media(max-width:767px) {
    #rock_web .heading1 {
        font-size: 2.68rem;
        font-weight: 200;
        text-align: left !important;
    }
    #rock_web header .navbar-brand img {
        height: 42px;
    }
    #rock_web .c-row {
        flex-wrap: wrap;
    }
    #rock_web .c-row li {
        width: 48%;
    }
    #rock_web .logo1 {
        height: 46px;
        margin-top: 2rem;
    }
    #rock_web .heading-div h2 {
        font-size: 2.8rem;
    }
    #rock_web :root {
        --gap: 4rem;
    }
    #rock_web .m-reverse,
    #rock_web .map-bg .row {
        flex-direction: column-reverse;
    }
    #rock_web .space-b {
        margin-bottom: 3rem;
    }
    #rock_web .text-md-end .c-btn {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 18px;
        margin-bottom: 1rem;
    }
    #rock_web picture img {
        width: 100%;
    }
    #rock_web .icon-with::after {
        display: none;
    }
    #rock_web .icon-group {
        padding: 1rem;
    }
    #rock_web .icon-with img {
        width: 50px;
        height: 50px;
    }
    #rock_web .builder-sec img {
        float: none;
        height: 45px;
        margin-bottom: 2rem;
    }
    #rock_web .footer-form ul {
        padding: 0 5rem;
        padding-bottom: 3rem;
    }
    #rock_web .footer-form form input,
    #rock_web .footer-form form textarea {
        padding: 2rem;
    }
    #rock_web .c-heading3 {
        font-size: 2.4rem;
    }
    #rock_web .sub-heading {
        font-size: 1.6rem;
    }
    #rock_web .group-1,
    #rock_web .group-2 {
        display: flex;
        gap: 1rem;
    }
    #rock_web .footer-info img:first-child,
    #rock_web .footer-info img:last-child {
        width: 184px;
        position: relative;
    }
    #rock_web .footer-info {
        padding: 2rem 0;
        padding-bottom: 0;
        margin-top: -4rem;
    }
    #rock_web .group-1 img {
        margin-top: -15rem;
    }
    #rock_web .group-m {
        display: none;
    }
    #rock_web .group-2 {
        justify-content: space-between;
        align-items: end;
        border-top: 60px solid;
    }
    #rock_web .group-2 .info-details {
        margin-left: auto;
    }
    #rock_web .group-2 img {
        margin-top: -6rem;
    }
    #rock_web .m-show {
        text-align: center;
        float: right;
        display: inline-block;
        margin-left: auto;
        margin-right: 2rem;
        margin-top: 4rem;
    }
    #rock_web .g-5,
    #rock_web .gx-5 {
        --bs-gutter-x: 0rem;
    }
    #rock_web footer {
        overflow-x: hidden;
    }
}