@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

:root {
  --yellow1: #e9aa55;
  --dark1: #3f2f15;
  --dark2: #5e4038;
  --dark3: #3f2f15;
  --new-font: "Ubuntu", sans-serif;
  --gap: 6rem;
}

/* .navbar-expand-lg.navbar-light,
footer {
    display: none;
} */
html {
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: var(--primary-font);
}
a {
  cursor: pointer !important;
}

body,
html {
  font-family: var(--new-font) !important;
}

/* Gap */
.sec-padd-t {
  padding-top: var(--gap);
}

.sec-padd-b {
  padding-bottom: var(--gap);
}

.sec-padd {
  padding: var(--gap) 0 var(--gap) 0;
}

/* Gap end */

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2.8rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  color: var(--orange);
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: 400;
}

img {
  max-width: 100%;
}

/* header css */

/*  */
#web_nav nav {
  position: fixed !important;
  top: 0;
  width: 100%;
  padding: 2rem 0;
}

.banner_web_img {
  position: absolute;
}

#web_nav.scrolled .navbar {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

#web_nav nav ul li a {
  color: var(--bs-white);
  background: transparent;
  padding: 1.5rem;
  font-weight: 400;
  transition: all 0.4s;
  font-size: 1.6rem;
}
.activeMenu {
  text-decoration: underline  !important
}

#web_nav nav img {
  height: 80px;
}
.doon_logo{
  height: 80px !important;
}
.web_banner {
  position: relative;
}
.web_banner::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.web_banner_text h2 {
  font-weight: 500;
  font-size: 3.2rem;
  margin-top: 1rem;
}

.web_banner_text {
  text-align: center;
  color: var(--bs-white);
  position: absolute;
  top: 15%;
  width: 100%;
  left: 0;
}

.web_banner_text img {
  height: 70px;
  /* margin-top: 32%; */
  margin-left: auto;
}

.web_right_img {
  text-align: right;
}

.community_web {
  background: var(--dark1);
}

.title_web {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 25.28px;
}

.heading_web {
  font-size: 5rem;
  font-weight: 700;
  line-height: 73.54px;
}

p {
  font-size: 1.6rem;
  font-weight: 300;
}

.community_web p {
  text-align: justify;
  color: var(--bs-white);
}

.community_web h2 {
  color: var(--yellow1);
}

.community_web h4 {
  color: var(--bs-white);
}

.community_web,
.community_web {
  position: relative;
}
.open-house{
  color: var(--dark2);
}

.community_web::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../images/new-website/arrow-img.webp) center right/ contain
    no-repeat;
  z-index: 0;
}

.commu_web_img {
  position: relative;
  z-index: 1;
  text-align: center;
}

.icon_main_web .icon_web {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.heading2_web {
  font-size: 4rem;
}

.web_ame h2,
.web_floor h2,
.site_web h2,
.form_web h2 {
  text-align: center;
  margin-bottom: 4rem;
  color: var(--dark2);
}

.icon_web div {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: var(--dark2);
}

.icon_web h3 {
  color: var(--dark2);
  font-size: 3rem;
  font-weight: 400;
}

.icon_main_web p {
  color: #444;
  font-weight: 400;
  line-height: 26px;
  margin-top: 2rem;
  width: 80%;
}

.web_map {
  background: var(--dark1);
  color: var(--bs-white);
}

.web_map h2 {
  color: var(--yellow1);
}

.web_map p {
  text-align: justify;
}

.web_floor {
  text-align: center;
}

.web_floor a {
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1rem 3rem;
  border-radius: 8px;
  color: var(--bs-white);
  background: var(--dark2);
  display: inline-block;
}

.site_web p {
  color: #383838;
  text-align: justify;
  font-weight: 400;
}

.builder_web {
  background: var(--dark1);
  color: var(--bs-white);
}
.builder_web p{
 text-align: justify;
}
.builder_web img {
  height: 200px;
}

.project_web h4 {
  text-align: center;
  color: var(--dark2);
  font-weight: 400;
}

.warranty_web {
  background: url(../../images/new-website/warranty.webp) center center / cover
    no-repeat;
  height: 900px;
}

.warranty_web img {
  height: 140px;
}

.warranty_web h2 {
  color: var(--dark2);
}

.warranty_web p {
  text-align: justify;
  color: #828282;
  font-weight: 400;
}

.warranty_web h5 {
  color: #5a5a5a;
  font-weight: 400;
}

.center_web_main {
  text-align: center;
}

.center_web_main img {
  margin-bottom: 3rem;
}

.center_web_main h2 {
  font-size: 5rem;
  font-weight: 400;
  line-height: 63.57px;
  color: #123256;
  padding-bottom: 2.5rem;
}

.center_web_main h4 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 23.84px;
  color: var(--dark2);
  border-bottom: 1px solid var(--dark2);
  display: inline-block;
  margin-bottom: 2.5rem;
}

.center_web_main h5 {
  color: var(--dark2);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 23.84px;
  margin-bottom: 3rem;
}

.flex-end-web {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  text-align: center;
}

.flex-end-web iframe {
  align-self: end;
}

.form_web h2 {
  font-weight: 400;
}

.form_web input[type="text"],
.form_web input[type="email"],
.form_web input[type="tel"],
.form_web textarea {
  /* padding: 1.5rem 2rem; */
  border-radius: 5px;
  box-shadow: none !important;
  font-size: 1.6rem;
  color: var(--dark2) !important;
  background: #f4f4f4 !important;
  border: none !important;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--dark2) !important;
  /* background: #f4f4f4 !important; */
}
.form_web .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none;
}
.form_web .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
  font-size: 1.5rem;
}
.form_web  .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
  background: #f4f4f4 !important;
  border-radius: 5px;

}
.form_web .css-1nkhxq8-MuiTypography-root, .form_web .css-1wc848c-MuiFormHelperText-root.Mui-error{
  font-size: 1.5rem;
  margin-left: 0;
  margin-bottom: 1rem;
}
.form_web  form label{
width: 100%;
margin-left: 0;
}
.form_web input::placeholder,
.form_web textarea::placeholder {
  color: #555555;
  font-weight: 400;
}

.form_web h4 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 20.68px;
  color: var(--dark2);
  margin-top: 1.2rem;
}

.form_web input[type="radio"] {
  padding: 0;
  width: 15px;
  height: 15px;
  box-shadow: none !important;
  accent-color: var(--dark2);
  border-color: 2px solid var(--dark2);
}

.form_web input[type="radio"]:checked {
  accent-color: var(--dark2);
  background: var(--dark2);
}

.form_web .web-radio {
  display: flex;
  align-items: center;
}

.form_web label {
  font-size: 1.4rem;
  margin-left: 1rem;
  color: var(--dark2);
  margin-top: 1rem;
  line-height: 20.68px;
}
 .submit_button {
  padding: 1.1rem;
    border-radius: 8px;
    font-size: 1.8rem;
    background: var(--dark2);
    color: var(--bs-white);
    font-weight: 400;
    cursor: pointer;
    border: none;
    display: block;
    width: 100%;
    margin-top: 3rem;
 }
.web-radio {
  margin-top: 1rem;
}

.form_web .web-radio label {
  margin-top: 0;
}

.form_web input[type="submit"] {
  padding: 1.1rem;
  border-radius: 8px;
  font-size: 1.8rem;
  background: var(--dark2) !important;
  color: var(--bs-white);
  font-weight: 400;
  cursor: pointer;
  border: none;
  display: block;
  width: 100%;
  margin-top: 3rem;
}

.web_footer {
  background: var(--dark2);
  padding: 3rem 0;
  padding-bottom: 1.5rem;
}

.web_footer .col-md-4:nth-child(2) {
  text-align: center;
  color: var(--bs-white);
}

.web_footer .col-md-4:nth-child(3) {
  text-align: right;
}

.web_footer h3 {
  font-size: 3.2rem;
}

.web_footer p {
  font-size: 1.8rem;
}

.web_footer ul {
  list-style: none;
  padding-left: 0;
  justify-content: center;
  display: flex;
  gap: 8%;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.web_footer ul li a {
  color: var(--bs-white);
  font-size: 1.4rem;
  font-weight: 400;
}

.web_footer_n {
  padding: 1.5rem 0;
}

.web_footer_n p {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--dark2);
  text-align: center;
}
.web_footer .col-md-4:nth-child(3) img {
  height: 130px;
}
@media (max-width: 992px) {
  #web_nav nav img {
    height: 60px;
  }
  #web_nav .navbar-toggler-icon {
    background-image: url(../../images/new-website/bar.svg);
    width: 2.5em;
    height: 2.5em;
    box-shadow: none !important;
  }
  .navbar-expand-lg #navbarWeb {
    background: var(--dark2);
    box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
    -moz-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
    -webkit-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
    padding: 2rem;
    z-index: 999;
    position: fixed;
    width: 70%;
    left: 0;
    top: 0%;
    height: 100% !important;
    transition: 0s ease-in-out !important;
    transition: 0s ease !important;
    transform: translateX(-35ch);
    transition-property: transform !important;
    transition-duration: 0.2s !important;
    transition-delay: 0.02s !important;
  }
  .navbar-expand-lg .navbar-collapse.show {
    transform: translateX(0ch) !important;
  }
}
.m-show {
  display: none;

}
.mb-45{
  margin-bottom: 2rem;
}
@media (min-width:768px) {
  .project_web .align-items-center{
    align-items: end !important;
  }

}
@media (max-width: 767px) {
  .web_banner img {
    height: 500px;
    object-fit: cover;
  }
  .web_banner h2 {
    font-size: 2.8rem;
  }
  .web_banner .web_right_img img {
    height: 100px;
    object-fit: cover;
    margin-top: 50%;
  }
  .community_web .row {
    flex-direction: column-reverse;
  }
  .commu_web_img img {
    height: 300px;
    object-fit: cover;
    margin-bottom: 2rem;
  }
  .community_web::after {
    background-position: center top;
    background-size: 161%;
  }
  .title_web {
    font-size: 1.6rem;
  }
  .community_web h2,
  .heading_web,
  .center_web_main h2 {
    font-size: 4rem;
  }
  p {
    font-size: 1.5rem;
  }
  .community_web p {
    padding-bottom: 3rem;
  }
  .home_web img {
    height: 300px;
    object-fit: cover;
  }
  .heading2_web {
    font-size: 3.4rem;
  }
  .icon_main_web .icon_web {
    justify-content: center;
  }
  .icon_main_web p {
    margin: 2rem auto;
    text-align: justify;
  }
  .icon_web div {
    width: 60px;
    height: 60px;
  }
  .icon_web h3 {
    font-size: 2.6rem;
  }
  .web_map img {
    padding-bottom: 3rem;
  }
  .web_floor a,
  .site_web h2 {
    margin-bottom: 3rem;
  }
  .site_web {
    padding-top: 0;
  }
  .site_web p {
    text-align: justify;
  }
  .builder_web img {
    height: 100px;
    margin-bottom: 2rem;
  }
  .builder_web h2,
  .warranty_web h5,
  .warranty_web h2 {
    text-align: center;
  }
  .builder_web p {
    text-align: justify;
  }
  .m-show {
    display: block;
  }
  .warranty_web img {
    height: 100px;
    margin: 0 auto;
    margin-bottom: 2rem;
    display: block;
  }
  .warranty_web {
    padding-top: 0;
    background: #e3ddda;
  }
  .center_web_main img {
    height: 100px;
    margin-bottom: 1rem;
  }
  .center_web_main h2 {
    padding-bottom: 1rem;
  }
  .center_web_main h4 {
    font-size: 1.7rem;
  }
  .center_web_main h5 {
    font-size: 1.6rem;
  }
  .form_web {
    padding-top: 0;
  }
  .web_footer,
  .web_footer .col-md-4:nth-child(3) {
    text-align: center;
  }
  .web_footer img {
    height: 100px;
    margin-bottom: 2rem;
  }
  .web_footer .col-md-4:nth-child(3) {
    margin-top: 3rem;
  }
  .web_footer h3 {
    font-size: 2.6rem;
  }
  .web_footer p {
    font-size: 1.6rem;
  }
  .web_footer ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    display: block;
  }
  .web_footer ul li {
    margin-bottom: 1rem;
  }
  .web_footer_n p {
    font-size: 1.4rem;
  }
  .doon_logo{
    height: 60px !important;
    margin-top: 2rem;
  }
}
