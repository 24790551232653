@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


:root {

    --primary1-font: "Josefin Sans", serif;
    --secondary1-font: "Cookie", serif;
    --mons-font: "Montserrat", serif;
    --dcofee: #4C3717;
    --cofee: #877250;
    --gap: 8rem;
    --light: #f8f5ef;
    --light2: #EDE9D7;
}

.woodweb img {
    max-width: 100%;

}

.bg-cofee {
    background: var(--cofee);
}

#woodweb {
    font-family: var(--primary1-font);
}

.most-font {
    font-family: var(--mons-font);
}

#woodweb p {
    color: var(--bs-black);
    /* font-size: 1.7rem; */
}

.westwood_header {
    background: #877250CC;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

.westwood_header.scrolled {
    background: var(--cofee);

}

#woodweb .westwood_header ul li a {
    text-transform: uppercase;
    color: var(--bs-white);
    font-weight: 400;
    background: transparent !important;

}

#woodweb .westwood_header ul li a.active {
    font-weight: 700;
}

.banner_wood {
    position: relative;
}

.wbanner_text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;
}

.wbanner_text img {
    height: 150px;
}

.wbanner_text h1 {
    color: var(--bs-white);
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 4rem;
    font-size: 6rem;
}

.end_img {
    text-align: center;
}

.end_img img {
    height: 8rem;
    width: auto;
    margin-right: -8rem;
}

.heading_w {
    color: var(--cofee);
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.heading_w b {
    font-weight: 700;

}

.heading_w span {
    font-weight: 400;
}

.bg-light01 {
    background: var(--light);
}

.flex_logo {
    display: flex;
    gap: 2rem;
    align-items: center;
    text-transform: uppercase;
    color: var(--cofee);
    font-size: 2.4rem;
    font-weight: 300;
    margin-bottom: 4rem;
}

.flex_logo img {
    height: 6rem;
    width: auto;
    margin: 2rem 0;
}

.bg-light02 {
    background: var(--light2);
}

.heading2_w {
    color: var(--cofee);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-size: 3.2rem;
}

.jose-font {
    font-family: var(--primary1-font);
}

.select_home h2 {
    margin-bottom: 6rem;
}

.big_btn {
    font-weight: 500 !important;
}

.btn_with {
    display: inline-flex;
    background: var(--cofee);
    padding: 1.4rem 2rem;
    margin-top: 6rem;
}

.btn_with a {
    height: 45px;
    width: 150px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
}

.btn_with a:nth-child(1) {
    background: #635237;
    color: var(--light);
}

.btn_with a:nth-child(2) {
    background: #EDE9D7CC;
    color: #877250CC;
}

.col-lg-1.p-0 {
    width: 2%;
}

.card_img {
    margin-top: 6rem;
}

.card_img .text-center p {
    font-weight: 600;
}

.card_img h3 {
    color: var(--cofee);
    font-weight: 600;
    margin-top: 1.5rem;
}

.margin_2rem {
    margin-top: -4rem;
}

.card_img h5 {
    font-weight: 500;
    margin-top: 1.5rem;
}

.card_img h5 b {
    font-weight: 700;

}

.card_img ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 3rem 0;
}

.card_img ul li {
    text-align: center;
    color: var(--bs-black);
    font-weight: 500;
    font-size: 1.5rem;
    width: 20%;
    display: grid;
    justify-content: center;
}

.card_img ul li img {
    height: 8rem;
}

.btn_dark {
    background: var(--cofee);
    color: var(--bs-white);
    padding: 1.4rem 2rem;
    font-weight: 300;
    text-transform: uppercase;
}

.d-font {
    font-family: var(--secondary1-font);
    font-weight: 400;
}

.heading_img {
    display: flex;
    gap: 0rem;
    justify-content: center;
    align-items: baseline;
}

.heading_img img {
    height: 8rem;
    width: auto;
}

.map_home p {
    font-family: var(--mons-font);
    font-size: 1.5rem;
}

.cofee_form h3 {
    color: var(--bs-white);
    font-family: var(--mons-font);
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.cofee_form h2 {
    color: var(--bs-white);
    font-family: var(--mons-font);

}

.cofee_form input[type="text"] {
    background: #F8F5EF;
    border-radius: 4px;
    font-size: 1.6rem;
    color: var(--cofee);
    padding: 1.2rem;
    box-shadow: none !important;
    width: 100%;

}

.cofee_form input::placeholder {
    opacity: 0.8;
}

.cofee_form form h4 {
    color: var(--bs-white);
    font-size: 1.6rem;
    font-family: var(--primary1-font);
    margin-top: 1.5rem;
}

.cofee_form label .css-ahj2mt-MuiTypography-root,
.cofee_form label .MuiFormControlLabel-label,
.cofee_form fieldset label .MuiFormControlLabel-label {
    color: var(--bs-white);
    font-size: 1.2rem;
    font-family: var(--primary1-font);

}

/* .cofee_form .css-11zohuh-MuiSvgIcon-root,
.cofee_form .css-1hbvpl3-MuiSvgIcon-root , */
.cofee_form input[type="radio"],
.cofee_form .MuiFormControl-fullWidth .css-hyxlzm {
    fill: var(--bs-white);
    color: var(--bs-white);
}

.icon_box {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 3rem 0;
}

.icon_box .icon_img {
    width: 190px;
    min-width: 190px;
}

.icon_box .icon_img img {
    width: 100%;
}

.icon_box .icon_content h5 {
    font-family: var(--mons-font);
    color: var(--bs-black);
    font-weight: 500;
}

.icon_box .icon_content p {
    color: var(--bs-black);
    font-family: var(--mons-font);
    font-size: 1.4rem;
}
.bg-light03{
    background: #F5ECD9;
}

.heading3_w {
    font-size: 2.6rem;
    color: var(--cofee);
    font-weight: 600;
}
.cofee-color{
    color: var(--cofee);
}

.community_w h4 {
    color: var(--cofee);
    font-weight: 700;
    margin-bottom: 2rem;
    font-size: 3rem;
}

.community_w p {
    font-weight: 400;
    font-family: var(--mons-font);
    font-size: 1.6rem;

}

.shadow_img {
    box-shadow: 0px 12px 20px 7px #00000030;
}

.btn_w {
    background: var(--cofee);
    padding: 1rem;
    text-transform: uppercase;
    color: var(--bs-white);
}

.img_div {
    padding: 2rem 1rem;
    background: var(--bs-white);
    border: 1px solid var(--cofee);
    margin-top: 2rem;
}

.cofee2_form h2 {
    font-weight: 400 !important;
    font-family: var(--secondary1-font);
    font-size: 4rem;
}

.cofee2_form input[type="text"],
.cofee2_form .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root,
.cofee2_form .text_field .MuiInputBase-fullWidth {
    background: var(--bs-white);
    border-radius: 4px;
    font-size: 1.4rem;
    color: #787878;
    padding: 1.2rem;
    box-shadow: none !important;
    width: 100%;
    font-family: var(--primary1-font);

}

.cofee2_form .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    margin-bottom: 2rem;
}

.cofee2_form input::placeholder {
    opacity: 0.6;
}

.cofee2_form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}

.cofee2_form h4 {
    font-size: 1.4rem;
    color: #787878;
    font-family: var(--primary1-font);
}

.cofee2_form h3 {
    font-size: 1.6rem !important;
    color: #787878;
    font-weight: 500;
}

.cofee2_form .css-1o84s3v-MuiFormControlLabel-root .MuiFormControlLabel-label,
.cofee2_form .fieldset .MuiTypography-body1 {
    font-size: 1.4rem;
    color: #787878;
}

.cofee2_form .css-1hbvpl3-MuiSvgIcon-root,
.cofee2_form .css-11zohuh-MuiSvgIcon-root {
    fill: #787878;

}

.WestFooter {
    background: var(--cofee);
    padding: 6rem 0;
}

.flex_img {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.flex_img img {
    height: 12rem;
}

.footer_nav {
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6rem;
}

.footer_nav li a {
    color: var(--bs-white);
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 400;
}

.bg-light03 {
    background: var(--light2);
}

.inner_wood .westwood_header {
    position: sticky;
}

.inner_wood .flex-column .nav-link {
    border: 1px solid var(--cofee);
    background: #E0DBC6;
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: var(--cofee);
    font-weight: 400;
    font-family: var(--mons-font);
}

.inner_wood .flex-column .nav-link.active {
    background: var(--cofee);
    color: var(--bs-white);
}

.inner_wood .tab-content {
    margin-top: 5rem;
}

.inner_wood .tab-content h5,
.inner_first h5 {
    font-family: var(--mons-font);
    margin-bottom: 2rem;
}

.inner_first h5 {
    margin-bottom: 1rem;
    font-weight: 600;
}

.inner_wood .tab-content p,
.inner_first p {
    font-family: var(--mons-font);
    font-size: 1.4rem;
    font-weight: 400;
}

.inner_first h6 {
    color: var(--bs-black);
    font-size: 1.8rem;
    font-family: var(--mons-font);
    font-weight: 500;
}

.inner_first .card_img {
    margin-top: 4rem;
}

#woodweb .cofee2_form p.Mui-error,
#woodweb .cofee2_form fieldset p {
    font-size: 1.4rem;
    margin-left: 0;
    margin-bottom: 0.5rem;
    color: #d32f2f;
    font-family: var(--primary1-font);
}

.bg_bark {
    background: #EDE9D7;
    padding: 3rem;
}

.f-500 {
    font-weight: 500 !important;
    line-height: 28.8px;
    font-size: 1.8rem;
    font-weight: 500;
}

.option1 {
    background: #77756c;
    color: #F8F5EF;
    padding: 0.8rem 2rem;
    border-radius: 6rem;
    font-size: 1.6rem;
}

.community_a h3 {
    color: var(--cofee);
    margin-bottom: 1rem;
}

.community_a p {
    font-weight: 400;
    font-family: var(--mons-font);
}

.deler_footer .c_heading {
    color: var(--cofee);
    font-size: 5rem;
}
.deler_footer .text_blue {
  font-weight: 400;
  color: var(--cofee) !important;

}

.deler_footer .sub-heading {
    color: var(--cofee);
    font-weight: 500;
}

.deler_footer .sub-heading span {
    font-weight: 700;
}

.deler_footer .footer-info {
    background: #F1EBDF;
    color: var(--cofee);
    position: relative;
    padding: 3rem 5rem;
    padding-bottom: 1rem;
}

.deler_footer .footer-info img:first-child,
.deler_footer .footer-info img:last-child {
    position: absolute;
    left: 0;
    bottom: 0;
}

.deler_footer .footer-info img:last-child {
    right: 0;
    left: auto;
}

.deler_footer .info-details h2 {
    font-weight: 700;
    font-size: 22px !important;
    margin-bottom: 6px;
}

.deler_footer .info-details h3,
.deler_footer .info-details p,
.deler_footer .footer-info h3 {
    font-size: 16px !important;
    font-weight: 400;
    color: var(--cofee) !important;

}

.deler_footer .text_blue {
    color: var(--blue2);
    text-align: center;
}

.deler_footer .info-details .col-md-4 img {
    position: relative;
}

.deler_footer .group-2 {
    text-align: right;
}

.deler_footer .group-m {
    text-align: center;
}

.deler_footer .group-m h3 {
    margin-top: 1rem;
}

.deler_footer .dealer_img {
    height: 320px;
}
.deler_footer .info-details p{
margin-bottom: 2rem;
}
.deler_footer .dealer_number{
    color: var(--cofee);
    font-size: 5rem;
    font-weight: 700;
}



@media (max-width: 992px) {
    #woodweb .westwood_header .navbar-toggler {
        filter: brightness(0) invert(1);
        opacity: 1;

    }

    #woodweb .westwood_header #navbarNavDropdown {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 256px;
        background-color: var(--cofee);
        box-shadow: 0 0 50px rgb(24 24 24 / 20%);
        -webkit-box-shadow: 0 0 50px rgb(24 24 24 / 20%);
        -moz-box-shadow: 0 0 50px rgb(24 24 24 / 20%);
        height: 100%;
        z-index: 999;
        transition: 0.0s ease-in-out !important;
        transition: 2.0s ease !important;
        transform: translateX(-35ch);
        transition-property: transform !important;
        transition-duration: 0.2s !important;
        transition-delay: 0.01s !important;
    }

    #woodweb .westwood_header #navbarNavDropdown.show {
        transform: translateX(0ch) !important;
    }

    .westwood_header {
        position: sticky;
        background: var(--cofee);
    }
}

@media (max-width:768px) {
    .wbanner_text img {
        height: 70px;
    }

    .wbanner_text h1 {
        font-size: 2.4rem !important;
        margin-top: 2rem;
        font-weight: 400;
    }

    .banner_sec1 {
        position: relative;
    }

    .wbanner_text {
        width: 95%;
        top: 20%;
    }

    :root {
        --gap: 6rem;

    }

    #woodweb h1 {
        font-size: 3.5rem;
    }

    #woodweb h2 {
        font-size: 3rem;
    }

    #woodweb h3,
    .community_w h4 {
        font-size: 2.2rem;
    }

    .flex_logo img {
        height: 5rem;
        margin: 1rem 0;
    }

    .card_img ul li {
        width: 33.33%;
    }

    .map_home .flex_logo {
        margin-bottom: 1rem;
    }

    .cofee_form input[type="text"],
    .inner_first h6 {
        font-size: 1.4rem;
    }

    .heading_img img {
        height: 6rem;
        width: auto;
    }

    .end_img img {
        height: 6rem;
        margin-right: -10rem;
    }

    .icon_box {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    .Neighbourhoods_sec .heading3_w {
        text-align: center;
        /* font-size: 2rem !important; */
    }

    .Neighbourhoods_sec .row .col-md-6:nth-child(2) {
        margin-top: -3rem;
    }

    .community_a h3 {
        margin-top: 2rem;
    }

    .flex_img img {
        height: 8rem;
    }

    .footer_nav {
        gap: 1rem;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        display: block;
        /* width: 90%;
    margin: 0 auto; */
        margin-top: 4rem;
    }

    .footer_nav li a {
        font-size: 1.3rem;
    }

    .footer_nav li {
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .inner_wood .tab-content {
        margin-top: 3rem;
    }

    .community_w h2,
    .map_home .fw-semibold,
    .community_a h3 {
        text-align: center;
    }

    #woodweb p {
        text-align: justify;
    }

    #woodweb .select_home h2 {
        font-size: 2.5rem;
    }

    #woodweb .heading3_w,
    #woodweb .cofee_form h3 {
        font-size: 2rem;
        text-align: center;
    }

    #woodweb .cofee_form h2 {
        font-size: 2.4rem;
    }

    .community_w p {
        font-size: 1.5rem;
    }

    .WestFooter {
        padding: 3rem 0;
    }
    .option1{
        font-size: 1.4rem;
    }
.sep_group p{
  text-align: center !important;
}
.flex_column_m {
    flex-direction: column-reverse;
}
.padding-1{
    padding: 1.5rem;
}
.padding-1 label{
    margin-top: 2rem;
    margin-bottom: 3rem !important;
}
.padding-1 .mt-5.pt-5.mb-5,#woodweb .cofee_form h2{
    text-align: center;

}
.deler_footer .footer-logo-div{
  text-align: center;
  margin-top: 4rem;
}
.deler_footer .sec-padd-b{
    padding-bottom: 3rem;
}
.deler_footer  .group-1 img {
    margin-top: -15rem;
}
.deler_footer .footer-logo-div p{
    text-align: center !important;
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--cofee) !important;
    margin-top: 1.5rem ;
}
.deler_footer .footer-info img:first-child, .deler_footer .footer-info img:last-child {
    width: 160px;
    position: relative;
    object-fit: contain;
    height: auto;
}
.deler_footer .group-2 {
    margin-top: 4rem;
}
.deler_footer .group-2 .dealer_img{
    margin-top: -4rem;
}
.deler_footer .m-hide{
    display: none;
}
.deler_footer .footer-info {
    padding: 2rem 0;
    padding-bottom: 0;
    margin-top: -2rem;
    background: transparent;
}
.deler_footer .group-1{
    border-top: 85px solid;
    border-color: #f8f5ef;
}
.deler_footer .group-1,.deler_footer .group-2 {
    display: flex;
    gap: 1rem;
    align-items: end;
    background: #F1EBDF;
}
.deler_footer .info-details h2{
    padding-top: 1rem;
}
.deler_footer .info-details h3:last-child{
    padding-bottom: 1rem;
}
.deler_footer .group-2 p{
    text-align: right !important;
}
}