@import url('https://fonts.googleapis.com/css2?family=Arya:wght@400;700&display=swap');


:root {
    --blue: #1A3153;
    --blue2: #123256;
    --dark: #5E4038;
    --light1: #f7ede7;
    --gap: 3rem;
    --south-font: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  html {
    font-family: var(--south-font);
    font-weight: 400;
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--south-font);
  }

  #south_design .dark_p {
    cursor: pointer !important;
  }
  #south_design p{
    text-align: justify;
  }
  /* Gap */
  #south_design p,  #south_design h1, #south_design h2, #south_design h3, #south_design h4,
  #south_design h5,  #south_design h6,  #south_design a,  #south_design input{
    font-family: var(--south-font) !important;

  }
  #south_design label,  #south_design input, #south_design textarea{
    font-size: 1.6rem;
  }
  #south_design .form-check{
    margin-left: 1rem;
  }
 #south_design .sec-padd-t {
    padding-top: var(--gap);
  }

 #south_design .sec-padd-b {
    padding-bottom: var(--gap);
  }

 #south_design .sec-padd {
    padding: var(--gap) 0 var(--gap) 0;
  }

  /* Gap end */
  #south_design  p{
    font-weight: 400;
  }
 #south_design img {
    max-width: 100%;
  }

  #south_design .banner-section {
    background: url(../../images/southcreek/banner.webp) center center/ cover no-repeat;

  }

  #south_design header .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 2rem 0;
  }

  #south_design header .navbar ul li a,
  #south_design header .navbar ul li a:hover {
    color: var(--bs-white);
    font-weight: 300;
  }

  #south_design header .navbar ul li a.active {
    font-weight: 600;
    color: var(--bs-white) !important;
    /* text-decoration: underline; */
  }

   .activeMenu {
    text-decoration: underline  !important
  }
  
  #south_design header .navbar ul li a{
    background: transparent;
    border: none;
    padding: 10px;
  }
  #south_design header .navbar-nav {
    gap: 1rem;
  }

  #south_design header .navbar-brand img {
    height: 90px;
  }

  #south_design  .banner-text {
    display: grid;
    justify-content: end;
    text-align: right;
    gap: 6rem;
    margin-top: 4rem;
    margin-right: 4rem;
    padding: 4rem 0;
  }

  #south_design .banner-text img {
    margin: 0 auto;
  }

  #south_design .banner-text img:first-child {
    height: 300px;
  }

  #south_design #main-header {
    transition: all 0.3s ease;
  }

  #south_design #main-header.scrolled .navbar {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 0;
  }

  #south_design  .bg-light1 {
    background: var(--light1);
  }

  #south_design  .title,
  #south_design  .title2 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.85px;
    color: var(--dark);
  }

  #south_design  .title2 {
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  #south_design  .c_heading {
    font-family: var(--primary-font);
    font-size: 45px;
    font-weight: 400;
    line-height: 63.57px;
    margin-bottom: 1.5rem;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

  #south_design  .blue-c {
    color: var(--blue);

  }

  #south_design  .dark_p {
    color: var(--dark);
  }

  #south_design  .banner-section {
    position: relative;
  }

  #south_design .left_tree {
    position: absolute;
    bottom: -100px;
    left: 0;
    top: auto;
  }

  #south_design  .right_tree {
    position: absolute;
    right: 0;
    top: -75px;
    z-index: 0;
  }

  #south_design  #Community {
    position: relative;
  }

  #south_design  .bg-blue {
    background: var(--blue2);
  }

  #south_design  .card_div h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 21.85px;

  }

  #south_design  .card_div {
    color: var(--bs-white);
  }

  #south_design  .map-sec h2 {
    margin-bottom: 0;
  }

  #south_design  .bg-dark1 {
    background: var(--dark);
  }

  #south_design  .map-sec img {
    padding-right: 3rem;
  }

  #south_design  .link_div {
    text-align: center;
    margin-top: 2rem;
  }

  #south_design  .link_div a {
    text-decoration: none;
    padding: 10px 40px 10px 40px;
    gap: 10px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.3s;
    color: var(--dark);
    background: var(--bs-white);
  }

  #south_design  .link_div a:hover {
    background: var(--light1);
  }

  #south_design .builder_Sec p {
    color: var(--bs-white);
  }

  #south_design  .project_p {
    text-align: center;
    padding-top: 3rem;
  }

  #south_design  .project_p span {
    font-weight: 300;
  }

  #south_design  .logo-sec .col-md {
    text-align: center;
  }

  #south_design  .dark_text {
    position: absolute;
    right: 4rem;
    top: 9rem;
    width: 40%;
  }

  #south_design  .warrenty_sec {
    position: relative;
  }

  #south_design  .map_location .title {
    text-decoration: underline;
  }

  #south_design  .map_location .dark_p {
    margin-bottom: 0;
    margin-top: 1.5rem;
    font-weight: 500;
  }

  #south_design  .map_location .dark_p+.dark_p {
    margin-top: 0;

  }

  #south_design  .map_div {
    margin-top: 2rem;
  }

  #south_design  .render_img {
    padding-left: 2rem;
  }

  #south_design  .flex-time {
    margin-top: 4rem;
    color: var(--lightbrown);
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  #south_design  .flex-time h3 {
    font-weight: 200;
    font-size: 6rem;
  }

  #south_design  .map-bg .flex-time+.flex-time {
    margin-top: 1rem;
  }

  #south_design  .right-side p {
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  #south_design .builder-sec img {
    float: right;
  }

  #south_design  .footer-form ul {
    list-style: none;
    padding-left: 0;

  }

  #south_design  .footer-form ul li {
    margin-top: 2rem;
    text-align: center;
  }

  #south_design  .footer-form form {
    padding-top: 1rem;
  }

  #south_design  .footer-form form input,
  #south_design  .footer-form form .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{

    background: var(--bs-white);
    /* padding: 1rem 2rem; */
    width: 100%;
    border: none !important;
    outline: none !important;
    color: var(--blue2);
    box-shadow: none !important;
  }
  #south_design  .footer-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;

  }

  #south_design  .footer-form form input::placeholder,
  #south_design  .footer-form form textarea::placeholder {
    color: var(--blue2);
    opacity: 0.8;
  }

  #south_design  .footer-form form input[type="radio"] {
    width: 10px;
    height: 10px;
    padding: 0;
    background: transparent;
    border: 2px solid var(--blue2);
    box-shadow: none;
  }

  #south_design  .footer-form form input[type="radio"]:checked {
    background: var(--blue2);
  }
  #south_design .footer-form p.Mui-error, #south_design .footer-form p.css-1nkhxq8-MuiTypography-root{
font-size: 1.5rem;
margin-left: 0;
margin-bottom: 1rem;
  }
  #south_design .footer-form form label,
  #south_design  .footer-form .MuiTypography-h4 {
    margin-bottom: 1rem;
    padding-top: 1rem;
    color: var(--blue2);
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
  }

  #south_design .footer-form form .form-check label, #south_design .footer-form form label {
    margin-bottom: 0;
    padding-top: 0;
  }
  #south_design .footer-form .css-1o84s3v-MuiFormControlLabel-root .MuiFormControlLabel-label{
    font-size: 1.6rem;
  }
  #south_design .footer-form form input[type="submit"] {
    padding: 10px 40px 10px 40px;
    background: var(--blue2);
    border-radius: 8px;
    color: var(--bs-white);
    font-size: 2rem;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    font-size: 18px;
  }

  #south_design .footer-form form button:hover {
    background: var(--brown1);
  }

  #south_design .footer-form form {
    padding-bottom: 5rem;
  }

  #south_design .sub-heading {
    font-weight: 400;
    font-size: 20px;
    color: var(--blue2);
  }

  #south_design .sub-heading span {
    font-weight: 600;
  }

  #south_design  .c-heading3 {
    font-weight: 700;
  }

  #south_design  .footer-info {
    background: var(--blue2);
    color: var(--bs-white);
    position: relative;
    padding: 3rem 5rem;
    padding-bottom: 1rem;
  }

  #south_design  .footer-info img:first-child,
  #south_design  .footer-info img:last-child {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  #south_design .footer-info img:last-child {
    right: 0;
    left: auto;
  }

  #south_design .info-details h2 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 6px;
  }

  #south_design .info-details h3,
  #south_design .info-details p,
  #south_design .footer-info h3 {
    font-size: 16px;
    font-weight: 400;
  }

  #south_design .text_blue {
    color: var(--blue2);
    text-align: center;
  }

  #south_design .info-details .col-md-4 img {
    position: relative;
  }

  #south_design .group-2 {
    text-align: right;
  }

  #south_design .group-m {
    text-align: center;
  }

  #south_design .group-m h3 {
    margin-top: 1rem;
  }

  #south_design  .m-show {
    display: none;
  }

  #south_design  .img_card img {
    width: 100%;
  }

  #south_design  .warrenty_sec {
    background: #3E210F;
  }

  @media (max-width: 991px) {
    #south_design   .navbar-expand-lg #navbarNav {
      background: var(--blue2);
      box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
      -moz-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
      -webkit-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
      padding: 2rem;
      z-index: 999;
      position: fixed;
      width: 70%;
      left: 0;
      top: 0%;
      height: 100% !important;
      transition: 0s ease-in-out !important;
      transition: 0s ease !important;
      transform: translateX(-35ch);
      transition-property: transform !important;
      transition-duration: 0.5s !important;
      transition-delay: 0.05s !important;
    }

    #south_design  .navbar-expand-lg .navbar-collapse.show {
      transform: translateX(0ch) !important;
    }

    #south_design  header .navbar-nav {
      gap: 0.5rem;
    }

    #south_design  .navbar-toggler-icon {
      width: 2.5em;
      height: 2.5em;
      box-shadow: none !important;
    }

    #south_design  .navbar-toggler {
      box-shadow: none !important;
      outline: none;
      border: none;
      margin-left: auto;
    }

    #south_design  .navbar-toggler-icon {
      background-image: url(../../images/southcreek/bar.svg);
    }
  }

  @media (max-width: 1400px) {

    #south_design  .footer-info img:first-child,
    #south_design  .footer-info img:last-child {
      max-width: 220px;
    }

    #south_design  .group-m img {
      max-width: 150px !important;
    }
  }

  @media (max-width:767px) {
    #south_design  .m-hide {
      display: none;
    }

    #south_design  .m-show {
      display: block;
    }

    #south_design  .banner-section {
      background: url(../../images/southcreek/mobile-banner.png) center center/ cover no-repeat;

    }

    #south_design  .banner-text img:first-child {
      height: 100px;
    }

    #south_design .banner-text img:last-child {
      height: 60px;
    }

    #south_design .banner-text {
      display: grid;
      justify-content: end;
      text-align: right;
      gap: 2rem;
      margin-top: 0rem;
      margin-right: 1rem;
      padding: 2rem 0;
      padding-bottom: 11rem;

    }
/*
    #south_design .navbar-brand {
      display: none;
    } */

    #south_design .right_tree {
      top: -135px;
      height: 230px;

    }

    #south_design .left_tree {
      bottom: -92px;
      height: 170px;
    }

    #south_design .s_img img {
      height: 260px;
      object-fit: cover;
    }

    #south_design  .c_heading {
      font-size: 40px;
    }

    #south_design  .map-sec img {
      padding-right: 0;
      margin-bottom: 2rem;
    }

    #south_design  .bg-dark1 .col-md-3+.col-md-3 {
      margin-top: 3rem;
    }

    #south_design  .logo-builder {
      height: 120px;
    }

    #south_design .logo-sec img {
      margin-top: 2rem;
    }

    #south_design  .map-sec {
      padding-bottom: 2rem;
    }

    #south_design  .dark_text {
      width: 92%;
      right: auto;
      top: 2rem;
    }

    #south_design  .heading_div {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
    }

    #south_design  .heading_div img {
      height: 100px;
    }

    #south_design .warrenty_sec {
      padding-top: 12rem;
    }

    #south_design .warrenty_sec .c_heading {
      margin-bottom: 0;
    }

    #south_design .heading_div {
      margin-bottom: 2rem;
    }

    #south_design  p {
      text-align: justify;
    }

    #south_design  .map_location p {
      text-align: center;
    }

    #south_design  .render_img {
      padding-left: 0;
      margin-top: 2rem;
    }

    #south_design  .map_div {
      display: none;
    }

    #south_design .c_heading {
      text-align: center;
    }

    #south_design  .custom_width {
      width: 80%;
      margin: 0 auto;
    }

    #south_design footer .c_heading {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: center;
      -webkit-text-fill-color: #123256;
      -webkit-text-stroke-width: 0;
      letter-spacing: 0;
      margin-bottom: 15px;
    }

    #south_design  .sub-heading {
      font-size: 18px;
    }

    #south_design .group-1,
    #south_design  .group-2 {
      display: flex;
      gap: 1rem;
      align-items: end;
      background: var(--blue2);
    }

    #south_design .footer-info img:first-child,
    #south_design .footer-info img:last-child {
      width: 184px;
      position: relative;
      object-fit: contain;
    }

    #south_design .footer-info {
      padding: 2rem 0;
      padding-bottom: 0;
      margin-top: -4rem;
    }

    #south_design .group-1 img {
      margin-top: -15rem;
    }

    #south_design  .group-m {
      display: none;
    }

    #south_design .group-2 {
      justify-content: space-between;
      align-items: end;
      border-top: 20px solid;
      border-color: #f7ede7;
    }

    #south_design .group-2 .info-details {
      margin-left: auto;

    }

    #south_design  .group-2 img {
      margin-top: -6rem;
    }

    #south_design  .footer-logo-div {
      text-align: center;
      margin: 3rem 0;
    }

    #south_design  .footer-logo-div p {
      text-align: center;
      display: none;
    }

    #south_design  .footer-info .col-md-4 {
      margin-top: 0;
    }

    #south_design  .info-details p {
      text-align: right;
    }

    #south_design  .group-1 .info-details p {
      text-align: left;
    }

    #south_design .group-1 {
      border-top: 94px solid;
      border-color: #f7ede7;
    }

    #south_design .footer-info {
      background: transparent;
    }

    #south_design  .info-details {
      padding: 1.5rem 0;
    }

    #south_design  {
      overflow-x: hidden;
    }
  }