
@font-face {
    font-family:"Muli", Sans-serif;
    src: url(./fonts/Muli.ttf);
    font-weight: 400;
}
@font-face {
    font-family:"Muli", Sans-serif;
    src: url(./fonts/MuliBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family:"Muli", Sans-serif;
    src: url(./fonts/MuliSemibold.ttf);
    font-weight: 700;
}

:root {
    --blue: #004274;
    --blue1:#003b63;
    --yellow:#f6b341;
    --primary-font:  "Roboto", system-ui;
     --secondry-font: "Darker Grotesque", system-ui;
     --trinary-font:"Muli", Sans-serif;
    --gap: 6rem;
}

html {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--primary-font);
}
a{
    cursor:pointer !important;
}
/* Gap */
.sec-padd-t {
    padding-top: var(--gap);
}

.sec-padd-b {
    padding-bottom: var(--gap);
}

.sec-padd {
    padding: var(--gap) 0 var(--gap) 0;
}

/* Gap end */

h1 {
    font-size: 4.5rem;
}

h2 {
    font-size: 4rem;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 2rem;
}

h5 {
    font-size: 1.6rem;
}

h6 {
    font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

a {
    color: var(--orange);
    text-decoration: none;
    font-size: 1.7rem;
    font-weight:400;
}

img {
    max-width: 100%;
}

/* header css */
.navbar.navbar-expand-lg {
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), -1px -3rem 1rem rgb(0 0 0 / 10%);
}
.navbar-expand-lg .navbar-nav .nav-link{
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--blue);
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
}
.main-header{
    padding-right: 15px;
}
.navbar-expand-lg .navbar-nav .nav-link:hover, .navbar-expand-lg .navbar-nav .nav-link:active ,
.navbar-nav .nav-item .dropdown-menu .dropdown-item:hover{
    color: #00aeef;
    background-color: rgba(0, 174, 255, 0.1);
}
.navbar-nav .nav-item .dropdown-menu{
    background-color: #f8f7f7;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 250px;
    padding: 0;
}
.navbar-nav .nav-item .dropdown-menu .dropdown-item{
    color: #004274;
    border-bottom: 1px solid #dce0e0;
    padding: 15px;
    line-height: 1.5;
    min-width: 250px;
    font-size: 14px;
}

.banner_sec{
    height: 400px;
    padding: 10px;
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.banner_sec::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#00233D;
    opacity: 0.65;
}
.inner_banner_text{
  position: relative;
  z-index: 1;
}
.inner_banner_text h1{
    font-family: var(--trinary-font);
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -2px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    color: var(--bs-white);
    padding: 0px 0px 0px 30px;
    border-style: solid;
    border-width: 0px 0px 0px 5px;
    border-color: #16BFBF;
}
.breadcrumb{
    padding: 0px 0px 0px 34px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.breadcrumb li a, .breadcrumb li{
    color: #16BFBF;
    font-size: 18px;
    font-family: var(--secondry-font);
    font-weight: 400;
}
.breadcrumb li:last-child{
    color: var(--bs-white);
}
.about_banner{
    background-image: url('./images/aboutBanner.jpg')
     /* src/images/aboutBanner.jpg */
    /* url(https://demo18.houzez.co/wp-content/uploads/2020/09/image-2.jpg); */
}
.heading_c{
    font-family: var(--trinary-font);
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
    color: var(--bs-black);
    padding-bottom: 2rem;
}
.content_sec{
    font-family: var(--primary-font);
    font-size: 15px;
    font-weight: 400;
    color: #222;
    line-height: 25px;
}
.content_sec ul{
    padding-left: 3rem;
}
.content_sec h3,.content_sec h4{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}
.value_div{
    width: 80%;
}
.value_div p{
    color: #636363;
}
.community_banner{
    background-image: url('./images/commBanner.jpg')
}
.blue_commmunity{
    background: var(--blue1);
    text-align: center;
    padding: 2rem 0;
}
.blue_commmunity h3{
   color: var(--yellow);
   font-family: sans-serif;
}
.blue_commmunity h3:nth-child(2){
    color: var(--bs-white);
    padding-top: 1rem;
}
.blue_commmunity p{
    color: var(--bs-white);
   font-family: sans-serif;

}
.blue_commmunity img{
    margin-bottom: 4rem;
}
.card_c{
    border-radius: 4px 4px 0 0;
    background: var(--bs-white);
    position: relative;
}
.labels-right{
    position: absolute;
    /* top: 17px; */
    top: 0px;
    right: 20px;
    z-index: 1;
}
.labels-right a {
    margin-left: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
    font-size: 10px;
    line-height: 11px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    padding: 3px 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.65);
}
.item-tools {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.item-tools  a{
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 4px;
}
.item-tools a  img{
    width: 100%;
    height: 20px;
}
.card_img{
    position: relative;
}
.img_effect{
    position: relative;
    display: block;
}
.img_effect::after{
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(0%, rgba(0, 0, 0, 0)), color-stop(50%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%);
    transition: all 0.3s;
}
.img_effect:hover::after{
    opacity: 0;
    transition: all 0.3s;

}
.card_c_content{
    padding: 20px;
    padding-bottom: 30px;
}
.card_c_content a{
    font-family: var(--primary-font);
    font-size: 1.6rem;
    font-weight: 500;
    color: #222;
}
.card_c_content a:hover{
    color:#16bfbf
}
.modal_big .modal-header{
    background: #2d2d2d;
    padding: 2rem;
}
.modal_big .modal-title{
    margin-left: auto;
    width: 90%;
    text-align: right;
    color: #fff;
    font-weight: 300;
    font-size: 15px;
}
.modal_big .btn-close{
    filter: brightness(0) invert(1);
    opacity: 1;
}
.right_modal .labels-right{
    position: relative;
    top: auto;
    right: auto;
}
.item-title{
    border-bottom: 1px solid #dce0e0;
}
.item-title a{
font-size: 18px;
font-weight: 500;
line-height: 22px;
}
.item-title a{
   color: #222222;
}
.item-title a:hover{
    color: #16bfbf;
}
p.item-title{
    color: #222222;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 2rem;
    padding-bottom: 1.5rem;
}
.c_btn{
    font-size: 15px;
   display: block;
   text-align: center;
    height: auto;
    line-height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none !important;
    padding:0 10px;
    border-radius: 6px;
    transition: all 0.3s;
}
.light_green{
    color: #fff;
    background-color: #16bfbf;
    border-color: #16bfbf;
}
.community_sec h2{
    font-family: "Muli", Sans-serif;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: -2px;
    line-height: 48px;
    padding-bottom: 2rem;
    color: #222;
}
.community_sec p{
    color: #020101;
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 2rem;

}
.community_sec .c_btn{
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
 font-size: 20px;
 font-weight: 600;
 text-transform: uppercase;
 letter-spacing: 0.5px;
font-family: var(--secondry-font);
 color: #FFFFFF;
 padding: 12px 24px;
 background: #818a91;
 border-color: #818a91;

}
.community_sec .c_btn:hover{
    background-color: #16bfbf;
    border-color: #16bfbf;
}
.community_sec{
   border-top: 1px solid  #222222;
}
.gallery_banner{
    background-image: url('./images/galleryBanner.jpg');
}
.heading_1{
    font-family: "Muli", Sans-serif;
    font-size: 45px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
  .gallery-item {
    width: 200px;
    cursor: pointer;
    margin: 5px;
  }
  .gallery-item img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 4px 4px 4px 4px;
  }

  .galleryInt {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
  .galleryInt-item {
    /* width: 180px; */
    cursor: pointer;
    margin: 5px;
  }
  .galleryInt-item img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 4px 4px 4px 4px;
  }

  .gallery_sec .row+.row{
    padding-top: 5rem;

  }
  .gallery_sec .row:nth-child(even){
    flex-direction: row-reverse;
  }
  .social_page{
    text-align: center;
  }
  .heading_2{
    font-size: 3.2rem;
    color: #000;
    line-height: 38px;
    font-weight: 500;
    line-height: 1.2;
  }
  .heading_3, .heading_4{
    font-size: 2.8rem;
    line-height: 34px;
    font-weight: 500;
    color: #000;
    line-height: 1.2;


  }
  .heading_4{
    font-size: 2.4rem;
  }
  .link_group{
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  .link_group a{
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 25px;
    color: #16bfbf;
  }
  .service_banner{
    background-image: url('./images/serviceBanner.jpg');
  }

  .heading_mix {
    font-family: "Muli", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
    color: #000000;
}
.heading_mix span {
    color: #16bfbf;
}
.service_content.bg_light  h2{
    text-align: center;
}
.service_content h2{
    padding-bottom: 2rem;
}
.service_content  ul{
    padding-left: 4rem;
}
.service_content  ul li{
    font-size: 1.5rem;
    font-weight: 400;
    color: #020101;
    line-height: 25px;
}
.service_content h5{
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}
.contact_banner{
    background-image: url('./images/contactBanner.jpg')
}
.contact_form form{
  margin-top: 4rem;
}
.contact_form form label,.contact_form form h6{
    font-size: 22px;
    line-height: 55px;
    color: #222;
    font-weight: 600;
}
.contact_form form input,.contact_form form select  {
    background-color: #ffffff;
    border: 2px solid #dce0e0 !important;
    font-family: var(--secondry-font);
    font-size: 22px;
    font-weight: 400;
    color: #00233d;
     height: 59px;
    padding: 0px 20px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    line-height: 31px;
}
.contact_form form input::placeholder{
    color: #00233d;
    font-weight: 400;
    font-family: var(--secondry-font);
}
.contact_form form  .css-1wc848c-MuiFormHelperText-root.Mui-error{
    font-size: 14px;
    margin-left: 0;
}
.contact_form form button{
    background-color: #16BFBF;
    font-family: var(--secondry-font);
    font-size: 24px;
    font-weight: 600;
    padding: 7px 30px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #fff;
    text-transform: capitalize;
    box-shadow: none !important;
}
.contact_form form button:hover{
    background-color: #16BFBF;
}
.contact_address h3{
    color:#222;
    font-weight: 500;
    padding-bottom: 20px;
}
.contact_address p{
    font-size: 1.5rem;
    line-height: 25px;
    color: #54595f;
    font-weight: 400;
    margin-bottom: 15px;
}
.contact_address p a{
    color: #16bfbf;
    font-weight: 600;
}
.useful_banner{
    background-image: url('./images/usefulBanner.jpg');
}
.pdf_link a{
    font-size: 20px;
    padding: 25px 50px;
    font-weight: 300;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: #3286CC;
    color: #fff;
    display: inline-block;
    line-height: 20px;
    gap: 1.5rem;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.usefull_Sec h2{
    font-weight: 500;
    padding-bottom: 4rem;
}
.list_half{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}
.list_half li{
    margin-left: 1rem;
}
.galleryInt-item,  .gallery-item{
    border-radius: 4px;
}
.header-logo{
    /* height: 70px;
    margin-left:10px; */
}

@media (max-width:992px) {
    .navbar.navbar-expand-lg{
      padding: 10px 0;
    }
    .header-logo{
        height: 60px;
    }
    .navbar-toggler{
        box-shadow: none !important;
    }
    .navbar-toggler-icon{
        width: 1.8em;
    height: 1.8em;
    }
    #navbarNav {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 256px;
        background-color: #ffffff;
        box-shadow: 0 0 50px rgb(24 24 24 / 20%);
        -webkit-box-shadow: 0 0 50px rgb(24 24 24 / 20%);
        -moz-box-shadow: 0 0 50px rgb(24 24 24 / 20%);
        height: 100%;
        z-index: 999;
        transition: 0.0s ease-in-out !important;
        transition: 2.0s ease !important;
        transform: translateX(-35ch);
        transition-property: transform !important;
        transition-duration: 0.2s !important;
        transition-delay: 0.01s !important;
    }
    .navbar-expand-lg .navbar-collapse.show {
        transform: translateX(0ch) !important;
    }
    .main-header{
        padding-right: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        color: #004274;
        border-bottom: 1px solid #dce0e0;
        background-color: #ffffff;
        padding: 15px;
    }
    .navbar-nav .nav-item:hover .dropdown-menu{
        box-shadow: none;
        border: none;
    }
    .navbar-nav .nav-item:hover .dropdown-menu .dropdown-item{
        padding: 15px 15px 15px 40px;
        color: #004274;
        border-bottom: 1px solid #dce0e0;
        background-color: #ffffff;
    }
    .navbar-nav .dropdown  .dropdown-menu.show{
       display: block;
    }
}

@media (max-width:767px) {
    .banner_text .inner_text h1{
        font-size: 30px;
    }
    .banner_text .inner_text p{
        font-size: 18px;
        line-height: 1.2em;
    }
    .inner_banner_text h1{
        font-size: 40px;
        letter-spacing: -1.5px;
    }
    .banner_sec{
        height: 240px;
    }
    .value_div{
        margin: 0 auto;
        margin-top: 4rem;
    }
    .blue_commmunity{
        padding: 2rem;
    }
    .card_c{
        margin-top: 2rem;
    }
    .gallery-item,.lg-react-element,.galleryInt-item {
        width: 100%;
    }
    .gallery-item,.galleryInt-item{
        margin: 0;
        margin-bottom: 2rem;
    }
    .list_half {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
    .list_half li + li{
        margin-top: 6px;
    }

}

.gallery-5 .lg-react-element {
    columns: 5;
    -webkit-columns: 5;
    -moz-columns: 5;
}

@media (max-width:767px) {
    .gallery-5 .lg-react-element {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
}

.gallery-4 .lg-react-element {
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
}

@media (max-width:767px) {
    .gallery-4 .lg-react-element {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
}