@font-face {
    font-family: "Muli", Sans-serif;
    src: url(../../fonts/Muli.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "Muli", Sans-serif;
    src: url(../../fonts/MuliBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: "Muli", Sans-serif;
    src: url(../../fonts/MuliSemibold.ttf);
    font-weight: 700;
}

:root {
    --primary-font: "Roboto", system-ui;
    --secondry-font: "Darker Grotesque", system-ui;
    --trinary-font: "Muli", Sans-serif;

}


.section {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 2rem;
    background-size: cover;
    background-position: center;
    animation: changeBackground 15s infinite;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0;
}

.overlay-text {
    position: relative;
    z-index: 1;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    /* Adds a slight transparency behind text */
    border-radius: 0px;
    height: 100%;
    width: 100%;

}


.custom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
}

.content-left {
    flex: 1;
    max-width: 50%;
    /* Left side max width */
}

.content-right {
    flex: 1;
    max-width: 50%;
    /* Right side max width */
}

.svg-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns */
    gap: 10px;
    /* Spacing between icons */
}

.svg-item {
    display: flex;
    flex-direction: column;
    /* Stack SVG and title */
    align-items: center;
    /* Center items */
}

.elementor-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg-title {
    margin-top: 10px;
    /* Space between SVG and title */
    text-align: center;
    /* Center title */
    font-size: 18px;
    font-weight: 600;
}

.houzez_section_title {
    font-family: var(--trinary-font);
    font-size: 37px;
    font-weight: 500;
    line-height: 1.3em;
    letter-spacing: -2px;
    margin-bottom: 0px;
}

.link_a {
    color: #16bfbf;
}

.elementor-icon svg {
    height: 40px;
    width: 40px;
}

.svg-title {
    font-family: var(--secondry-font);
    font-size: 22px;
    font-weight: 600;
    margin: 2rem 0;
    padding-bottom: 1rem;
}

.overlay_green {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 0px 0px -100px;
    padding: 80px 80px 80px 80px;
    background: #16BFBF;
    width: 100%;
    margin-top: 22rem;
    z-index: 1;
    position: relative;
}

.margin_blue {
    margin-top: -10.5rem;
}

.overlay_green .text-content {
    max-width: 100%;
}

.overlay_green .text-content h1 {
    font-family: "Muli", Sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
    color: #fff;
}

.overlay_green .text-content p {
    font-family: var(--secondry-font);
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    line-height: 25px;
}

.sec-padd-b1 {
    margin-bottom: -15px;
}

.hidden {
    overflow-x: hidden;
}

section .btn_outline {
    font-family: var(--secondry-font);
    font-size: 20px;
    font-weight: 600;
    padding: 16px 40px !important;
    border-radius: 5px !important;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #FFFFFF !important;
    background-color: #16BFBF !important;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #FFFFFF !important;

}

/* Responsive Styles */
@media (max-width: 768px) {
    .custom-section {
        flex-direction: column;
        /* Stack vertically on small screens */
    }

    .content-left,
    .content-right {
        max-width: 100%;
        /* Allow full width on smaller screens */
    }

    .svg-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns on small screens */
    }
}

@media (max-width: 480px) {
    .svg-container {
        grid-template-columns: 1fr;
        /* 1 column on very small screens */
    }
}



/* Background image animation */
@keyframes changeBackground {
    0% {
        background-image: url('../../images//slider1.jpg');
    }

    50% {
        background-image: url('../../images//slider2.jpg');
    }

    100% {
        background-image: url('../../images//slider1.jpg');
    }

    /* 0% { background-image: url('https://crescenthomes.ca/wp-content/uploads/2021/05/928-Chapel-Hill-Crt-Kitchener-17-scaled-1.jpg'); }
    50% { background-image: url('https:crescenthomes.ca/wp-content/uploads/2021/05/image-12.jpg'); }
    100% { background-image: url('https://crescenthomes.ca/wp-content/uploads/2021/05/928-Chapel-Hill-Crt-Kitchener-17-scaled-1.jpg'); } */

    /* 100% { background-image: url('https://crescenthomes.ca/wp-content/uploads/2021/05/928-Chapel-Hill-Crt-Kitchener-17-scaled-1.jpg'); } */
}

/* Optional responsive styles */
@media (max-width: 768px) {
    .section {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .section {
        font-size: 1.2rem;
    }
}



.section-2 {
    position: relative;
    width: 100%;
}

.image {
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(38, 166, 154, 0.9);
    /* teal-500 with opacity */
    padding: 2rem;
}

.text-content {
    color: white;
    max-width: 24rem;
}

.text-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.text-content p {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
}

.text-content button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.text-content button:hover {
    background-color: white;
    color: #26a69a;
    /* teal-500 */
}

.elementor-shape-bottom {
    height: 30px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    bottom: -1px;
    z-index: 1;

}

.elementor-shape-bottom svg {
    height: 30px;
    width: 100%;
    opacity: 1;
}

.elementor-shape-bottom svg path {
    fill: #fff;
}

.banner_text {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: left;


}

.inner_text {
    width: 80%;
}

.inner_text h1 {
    font-family: "Muli", Sans-serif;
    font-size: 50px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
}

.inner_text p {
    font-family: var(--secondry-font);
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 35px;
    line-height: 25px;
}

/* section 2 end */

/* style.css */

.section-design {

    padding: 100px 0;
    text-align: left;
    background-color: #00233D;
}

.highlight {
    color: #16BFBF;
}

.quality {
    font-size: 32px;
    font-family: var(--primary-font);
    font-weight: 500;
    text-transform: inherit;
    color: #fff;
}

.subtext {
    font-family: var(--secondry-font);
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    color: #fff;

}

.line {
    width: 50px;
    height: 2px;
    background-color: #4EC3C8;
    margin-bottom: 20px;
}

.bg_light {
    background: #f8f8f8;
}

/* section 4 start */

.building {
    text-align: center;
    padding: 50px 20px;
}

.building h1 {
    font-family: "Muli", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
    color: #000000;
}

.building h1 span {
    color: #16bfbf;
}

.building p {
    font-family: var(--secondry-font);
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    color: #000000;
    line-height: 25px;

}

.content {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #fff;
}

.content .box {
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    border-radius: 10px;
}

.content .box img {
    width: 100%;
    /* border-radius: 10px; */
}

.content .box h3 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 20px 0 10px;
}

.content .box p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.content .box a {
    text-decoration: none;
    color: #00aaff;
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
}

.content .box a i {
    margin-right: 5px;
}

.box_sec .box {
    padding: 30px;
    padding-top: 0;
}

.box_sec .box img {
    box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;

}

.box_sec .box h3 {
    font-size: 3.2rem;
    font-family: var(--primary-font);
    margin-bottom: 20px;
    font-weight: 500;

}

.box_sec .box p {
    font-weight: 400;
    color: #000;
    font-size: 1.5rem;
    font-family: var(--primary-font);
    margin-bottom: 30px;

}

.box_sec .box a {
    font-family: var(--secondry-font);
    font-size: 1.8rem;
    color: #222;
    font-weight: 700;
    line-height: 25px;
}

/* section 5 */

.high-end {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('../../images/gallery-cover.jpg') center center / cover fixed;

}

.high-end-content {
    background-color: white;
    padding: 81px 81px 81px 81px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.high-end-content h1 {
    font-family: "Muli", Sans-serif;
    font-size: 45px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
}

.high-end-content p {
    font-family: var(--secondry-font);
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    color: #000;
    line-height: 25px;
}

.high-end-content button {
    background-color: #16BFBFB3;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    font-family: var(--secondry-font);
}

.high-end-content button:hover {
    background-color: #16BFBF;
}

/* section 6 strart */
.elementor-background-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: transparent;
    background-image: linear-gradient(180deg, #00233DDE 0%, #00233D 100%);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.expert-container {
    padding: 50px 20px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #00233D;
    background-image: url(https://demo18.houzez.co/wp-content/uploads/2020/09/image-6.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

}

.expert-content,
footer .logo,
footer .footer {
    z-index: 1;
    position: relative;
}

.expert-content {
    max-width: 800px;
}

.expert-content h1 {
    font-family: "Muli", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 20px;
    color: #FFFFFF;
}

.expert-content h1 span {
    color: #16bfbf;
}

.expert-content p {
    font-family: var(--secondry-font);
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 60px;
    color: #FFFFFF;

    line-height: 25px;
}

.footer {
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
    text-transform: none;
    color: #fff;
}

.button {
    background-color: #4EC3C9;
    color: #FFFFFF;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    border-radius: 5px;
}

.logo {
    margin-top: 40px;
}

.footer {
    margin-top: 50px;
}

.elementor-shape-top {
    top: -1px;
    bottom: auto;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
    }

    .content {
        text-align: left;
    }

    .logo {
        margin-top: 0;
    }
}

@media (max-width:767px) {
    .section {
        height: 62vh;
    }

    .overlay_green {
        margin: 0 auto;
        margin-top: -15px;
        padding: 35px;
        min-width: fit-content;
    }

    .margin_blue {
        margin-top: -0.5rem;
        text-align: center;
    }

    .overlay_green .text-content h1 {
        font-size: 29px;
    }

    section .btn_outline {
        padding: 10px 15px !important;
    }

    .home_green_btn {
        text-align: center;
    }

    .line {
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .margin_blue .quality {
        margin-bottom: 20px;
    }

    .margin_blue .quality,
    .margin_blue .subtext {
        text-align: center;
    }

    .subtext {
        line-height: 25px;
    }

    .building p br {
        display: none;
    }

    .row .box+.box {
        margin-top: 3rem;
    }
    .high-end-content{
        padding: 35px;
    }
    .high-end-content button{
        padding: 10px 30px;
    }
    footer .logo {
        text-align: center;
    }
    footer .footer{
text-align: center;
    }
}


.download-button {
    font-family: "Darker Grotesque", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #16BFBFB3;
    padding: 20px 40px;
    border-radius: 5px;
    outline: none;
    border: none;
}