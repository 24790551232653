@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#oterbein p,
#oterbein h1,
#oterbein h2,
#oterbein h3,
#oterbein h4,
#oterbein h5,
#oterbein h6,
#oterbein a,
#oterbein input ,
#oterbein ul li{
    font-family: "Roboto", sans-serif !important;

}


#oterbein header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    background-color: var(--bs-white);
    box-shadow: none;
}

#oterbein header .navbar.navbar-expand-lg {
    box-shadow: none;

}

#oterbein .scrolled {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 12px 12px 0 rgb(0 0 0 / 16%);
    min-height: 65px;
    background-color: var(--bs-white);
}

#oterbein .navbar-brand img {
    max-width: 152px;
    width: 100%;
}

#oterbein header a {
    display: block;
    text-decoration: none;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
    padding: 40px 13px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    background: transparent !important;
    font-family: "Source Code Pro", sans-serif;
    ;
}

#oterbein header a:hover {
    color: #fa360a;
}

#oterbein header a.navbar-brand {
    padding: 0;
}

#oterbein .hero-section {
    position: relative;
    overflow: hidden;
}

#oterbein .hero-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / .45);
}

#oterbein .icon_top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

#oterbein .icon_dots {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;

}

#oterbein .abs_banner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
#oterbein .abs_logo {
    position: absolute;
    top: 13%;
    /* transform: translateY(-50%); */
    z-index: 2;
    left: 50%;
    transform: translate(-50%, -50%);
}

#oterbein .abs_banner h5 {
    font-size: 18px;
    font-weight: 500;
    color: var(--bs-white);
    padding-left: 20px;
    margin-bottom: 10px;
}
#oterbein .abs_banner h1{
    font-size: 46px;
    color: var(--bs-white);
    line-height: 50px;
    position: relative;
    padding-left: 20px;
}
#oterbein .abs_banner h1:before {
    content: "";
    height: 91px;
    width: 5px;
    background-color: #fa360a;
    position: absolute;
    bottom: 3px;
    left: 0;
}
#oterbein .abs_banner p{
    color: var(--bs-white);
    font-weight: 400;
    font-size: 16px;
    max-width: 84.8%;
    width: 100%;
    margin: 3rem 0;
}
#oterbein .abs_banner  a{
    padding: 17px 30px 17px 30px;
    display: inline-block;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    background-color: #fff;
    border: none;
    border-radius: 50px;
    box-shadow: 0 6px 27px 0 rgb(0 0 0 / .1);
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 5;
}
#oterbein .abs_banner  a svg {
    margin-left: 7px;
    transition: all 0.4s ease;
    color: #fa360a;
    font-size: 2.5rem;
}
.activeMenu {
    text-decoration: underline  !important
  }
#oterbein .icon_bottom{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
}
#oterbein .gray_img{
    background: url(../../images/otterbeinwoods/figure16.webp)  ;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
}
#oterbein .sec-padd{
    padding: 7rem 0;
}
#oterbein .heading1{
    font-size: 36px;
    font-weight: 700;
    color: var(--bs-black);
    line-height: 36px;
    margin-bottom: 30px;
}
#oterbein .features h5{
    font-size: 20px;
    line-height: 40px;
    color: #000;
    font-weight: 500;
    display: inline-block;
    padding: 8px;
    position: relative;
    text-transform: uppercase;
    border-bottom: 3px solid #fa360a;
    margin-bottom: 4rem;
}
#oterbein .list-unstyled{
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
    color: #000;
    text-align: left;
    padding-left: 0;
    position: relative;

}
#oterbein .list-unstyled li{
  display: flex;
  gap: 1rem;
  align-items: baseline;

}
#oterbein .list-unstyled li svg{
    width: 8%;
    margin-top: 10px;
    color: #fa360a;
    font-size: 18px;
}
#oterbein .warranty-section{
    background-color: #F8F8F8;
    background-image: url(../../images/otterbeinwoods/figure29.webp);
    background-position: bottom left;
    background-repeat: no-repeat;
    padding-top: 14rem;
    padding-bottom: 10rem;
}
#oterbein .warranty-section  h2{
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin: 2rem 0;
}
#oterbein .warranty-section p{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 28px;
    color: #444;
    margin-bottom: 20px;
}
#oterbein .floor-plans-section h2{
  font-weight: 400;
  font-size: 2.6rem;
  margin-bottom: 3rem;
}
#oterbein #floorPlanTabs{
    border: none;
}
#oterbein #floorPlanTabs .nav-item{
    -ms-flex: 1;
    flex: 1 ;
    display: flex;
    margin-bottom: 15px;
}
#oterbein #floorPlanTabs .nav-link{
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    color: #3f4448;
    background-color: #f8f8f8;
    text-align: center;
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 25px;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -ms-flex: 1 !important;
    flex: 1 !important;
    border: none;
    border-radius: 0;
}
#oterbein #floorPlanTabs .nav-link.active{
background-color: #2aa090;
color: #fff;
position: relative;
}
#oterbein #floorPlanTabs .nav-link.active:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -6px;
    left: 50%;
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-top: 6px solid;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-top-color: #2aa090;
}
#oterbein .tab-content p{
    color: #4a5865;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.4px;
    font-size: 16px;
}
#oterbein ul.apartment-items {
    margin: 2rem 0;
    margin-bottom: 4rem;
    padding-left: 0;
}
#oterbein ul.apartment-items li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 0;
    border-bottom: 1px solid #4a5865;
    font-size: 13px;
    cursor: pointer;
    color: #4a5865;
}
#oterbein ul.apartment-items li .value-list {
    font-weight: 500;
    color: #4a5865;
    padding-right: 4%;
}
#oterbein .btn-outline-primary {
    color: #2aa090;
    border-color: #2aa090;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0;
}
#oterbein .btn-outline-primary:hover{
    background: #2aa090;
    color: var(--bs-white);
}
#oterbein .about-contact{
    background-color: #111;
    background-image: url(../../images/otterbeinwoods/figure6.webp);
    background-position: top right;
    background-repeat: no-repeat;
    color: var(--bs-white);
}
#oterbein  .about-contact h2{
font-size: 18px;
margin-bottom: 3rem;
}
#oterbein  .about-contact p{
    font-size: 16px;
    font-weight: 400;
}
#oterbein .flip_main img{
    width: 100%;
}
#oterbein  .box-front, #oterbein  .flip_main .box-hover{
    background-color: #088EB7;
    padding: 20px 20px 15px 20px;
}
#oterbein  .flip_main{
    position: relative;
    overflow: hidden;
}
#oterbein  .flip_main h3{
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
}
#oterbein  .flip_main p:first-child{
    border-top: 1px solid #56CCF2;
    padding-top: 10px;
    margin-bottom: 20px;
}
#oterbein  .flip_main .box-hover{
    position: absolute;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    top: 104%;
    width: 100%;
    height: 100%;
}
#oterbein  .flip_main:hover .box-hover{
    top: 0;
}
#oterbein .about-contact .contact_wood h2{
    font-size: 30px;
    font-weight: 600;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 30px;
    text-align: left;
}
#oterbein .about-contact .contact_wood .css-xdmu94-MuiContainer-root{
  padding: 0;
}
#oterbein .about-contact .contact_wood h2::before {
    content: "";
    height: 4px;
    width: 15px;
    background-color: #ffa996;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    border: none;
}
#oterbein .about-contact .contact_wood h2::after{
    content: "";
    height: 4px;
    width: 34px;
    background-color: #fa360a;
    position: absolute;
    bottom: 0;
    left: 21px;
    border-radius: 10px;
    border: none;
}
#oterbein .about-contact form  input[type="text"],#oterbein .about-contact form  input[type="tel"],
#oterbein .about-contact form  input[type="email"] {
    background-color: #3b3b3b;
    border-color: #3b3b3b;
    /* height: 52px; */
    border-radius: 7px;
    padding: 13px 15px;
    box-shadow: none !important;
    font-size: 16px;
    color: var(--bs-white);
}
#oterbein .about-contact .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root,
.woods_contact .MuiInputBase-fullWidth{
    background-color: #3b3b3b;
    border-color: #3b3b3b;
    box-shadow: none !important;
    font-size: 16px;
    color: var(--bs-white);
}
#oterbein .about-contact  h4{
    font-size: 1.6rem;
}
#oterbein .about-contact .css-1wc848c-MuiFormHelperText-root.Mui-error,
#oterbein .about-contact .css-1nkhxq8-MuiTypography-root{
    font-size: 1.5rem;
    margin-left: 0;
    margin-bottom: 1rem;
}
#oterbein .about-contact .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label,
.wood_radio .MuiFormControlLabel-label{
font-size: 1.6rem;
}
#oterbein .about-contact form label{
    width: 100%;
}
#oterbein .about-contact form  input::placeholder{
    color: var(--bs-white);

}
#oterbein .about-contact .css-1hbvpl3-MuiSvgIcon-root path{
    border-color: #fff;
    fill: #fff;
}
#oterbein .about-contact form  label{
    font-size: 16px;
}
#oterbein .about-contact form input[type="radio"],
#oterbein .about-contact form .wood_radio input[type="radio"]{
    width: 1.4em;
    height: 1.4em;
    margin-right: 10px;
}
#oterbein .about-contact form  button{
    background-color: #FA360A ;
    border-style: solid;
    border-width: 0 0 0 0;
    border-radius: 4px 4px 4px 4px;
    padding: 18px 34px 18px 34px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-top: 3rem;
    width: 200px;
}
#oterbein .about-contact form  button:hover{
    background-color:var(--bs-white) ;
    color: #FA360A;
}
#oterbein .copy-right{
    align-items: center;
    margin-top: 50px;
    color: #444;
    font-size: 16px;
    padding-bottom: 2rem;
    font-weight: 500;
}
#oterbein .copy-right p{
    color: #444;
    font-weight: 500;


}
#oterbein .navbar-toggler-icon {
    background-image: url(../../images/otterbeinwoods/bar.svg);
    width: 2.5em;
    height: 2.5em;
    box-shadow: none !important;
  }
  #oterbein .logo_warr{
    height: 80px;
}
#oterbein .contact_wood .css-hyxlzm,
#oterbein .contact_wood fieldset .css-hyxlzm{
   color: var(--bs-white);
   fill: var(--bs-white);
}
  @media (max-width:767px) {
    #oterbein .hero-section .w-100{
    height: 500px;
    object-fit: cover;
   }
   #oterbein  .icon_top,  #oterbein .icon_bottom,  #oterbein .icon_dots{
    display: none;
   }
   #oterbein .abs_banner h5{
    font-size: 14px;
    padding-left: 0;

   }
   #oterbein .abs_banner h1{
    font-size: 36px;
    line-height: 42px;
    padding-left: 0;
   }
   #oterbein .abs_banner h1:before{
    display: none;
   }
   #oterbein .abs_banner p{
    max-width: 100%;
   }
   #oterbein .gray_img{
    height: 180px;
   }
   #oterbein .heading1 {
    font-size: 28px;
}
#oterbein .warranty-section{
    padding-top: 6rem;
    background-image: none;
    padding-bottom: 0;
}
#oterbein .tab-content img{
    display: none;
}
#oterbein .about-contact form {
    margin-top: 3rem;

}
#oterbein .warranty-section p,#oterbein .tab-content p,#oterbein .about-contact p{
    text-align: justify;
}
#oterbein .copy-right{
    margin: 1.5rem 0;
    padding-bottom: 0;
}
#oterbein .contact_wood {
    padding-top: 3rem;
}
  }