@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

:root {
    --redg: linear-gradient(to right, #ff5e17, #fa360a);
    --dorange: #fa360a;
    --dgreen: #021311;
    --geo-font: "Source Sans 3", sans-serif;
    --gap: 5rem;
}

html,
#thegeorgie {
    font-family: var(--geo-font);
    font-weight: 400;
    font-size: 62.5%;
}

body,
#thegeorgie {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--geo-font);
    background: var(--bs-white);
    color: var(--brown);
}

#thegeorgie .top-header {
    background: var(--bs-black);
}

#thegeorgie .mainheader.scrolled {
    position: sticky;
    top: 0;
    z-index: 9;
}

#thegeorgie .mainheader.scrolled .navbar-expand-lg {
    background: #fa360a;
    border: none;
}

#thegeorgie .top-header ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.5rem;
    margin-bottom: 0;
    padding-left: 0;
}

#thegeorgie .top-header ul li a {
    color: var(--bs-white);
}

#thegeorgie .top-header ul li a svg {
    color: var(--bs-white);
    transition: all 0.3s ease-in-out;
}

#thegeorgie .top-header ul li a:hover svg {
    color: var(--dorange);
}

#thegeorgie p {
    font-size: 1.8rem;
    color: #444;
    font-weight: 400;
}

#thegeorgie .header-btn {
    display: block;
    color: #fff;
    background-image: linear-gradient(to right, #ff5e17, #fa360a);
    font-size: 16px;
    padding: 12px 34px;
    border: none;
    position: relative;
    z-index: 5;
    transition: all 0.3s ease-in-out;
    margin-left: 2rem;
}

#thegeorgie header .navbar.navbar-expand-lg {
    background: var(--dgreen);
    border-bottom: 1px solid #354241;
}

#thegeorgie header .navbar-brand img {
    max-width: 152px;
    width: 100%;
}

#thegeorgie header .navbar-expand-lg .navbar-collapse {
    justify-content: center;
}

#thegeorgie header .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--bs-white);
    background: transparent;
    font-size: 1.6rem;
}

#thegeorgie .banner_sec1 {
    background: var(--dgreen);
    position: relative;
}

#thegeorgie .img_sec1 {
    position: absolute;
    bottom: 0;
    left: 0;
}

#thegeorgie .form_width {
    padding: 3rem 0;
}

#thegeorgie .form_width h3 {
    color: var(--bs-black);
    font-size: 3.6rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

#thegeorgie .form_width p {
    font-size: 1.7rem;
}

#thegeorgie .geo_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#thegeorgie .geo_flex img {
    height: 100px;
}

#thegeorgie .geo_flex p {
    width: 50%;
    font-size: 1.8rem;
    color: #444;
    font-weight: 400;
    line-height: 28px;
}

#thegeorgie .geo_form {
    margin-top: 2rem;
}

#thegeorgie .geo_form .form-control {
    font-size: 1.6rem;
    color: #111;
    font-size: 15px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    box-shadow: none !important;
    padding: 12px 15px;
    border-radius: 7px;
}

#thegeorgie .geo_form .form-control::placeholder {
    opacity: 0.6;
}

#thegeorgie .geo_form input[type="submit"] {
    background: #fa360a;
    margin-top: 2px;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding: 13px 34px;
    border: none;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    position: relative;
}


#thegeorgie .submit_button {
    margin-top: 2px;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #fa360a;
    padding: 13px 34px;
    border: none;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    position: relative;
}


#thegeorgie .geo_footer {
    padding: 60px 0 80px 0;
    background-color: #1A1D29;
    color: #444444;
    font-size: 16px;
    border-top: none;
}

#thegeorgie .geo_footer_menu {
    padding: 20px 0;
    background-color: #1F222F;
}

#thegeorgie .copyright-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#thegeorgie main {
    z-index: 1;
    position: relative;
    background: var(--bs-white);
}

#thegeorgie .geo_footer_main h5,
#thegeorgie .geo_footer_main p {
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    color: #C9CACC;
    margin-bottom: 30px;
}

#thegeorgie .geo_footer_main p {
    font-weight: 400;
}

#thegeorgie .footer-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 2.5rem;
}

#thegeorgie .footer-menu a {
    color: #A3A4AA;
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: none;
    outline: 0 none;
    transition: all 0.3s ease-in-out;
}

#thegeorgie .footer-menu a:hover,
#thegeorgie .copyright a {
    color: #fa360a;
}

#thegeorgie .copyright {
    font-size: 1.8rem;
    color: #A3A4AA;
}
#thegeorgie .geo-banner{
    position: relative;
    padding: 150px 0 150px;
    position: relative;
    z-index: 2;
    background: url(../../images/thegeorgie/banner1.webp) bottom/ cover no-repeat;

}
#thegeorgie .geo-banner.geo-page-banner{
    background: url(../../images/thegeorgie/banner2.webp) bottom/ cover no-repeat;

}
#thegeorgie .geo-banner.geo-page-banner::after{
    display: none;
}
#thegeorgie .geo-banner::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 0;
}
#thegeorgie .geo-banner h1{
    line-height: 52px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 42px;
    z-index: 2;
    position: relative;
}
#thegeorgie .geo-banner .top_icon{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
#thegeorgie .geo-banner .bottom_icon{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
}
#thegeorgie .features-page h2{
    color: var(--bs-black);
}
#thegeorgie .features-page h5{
    font-size: 2rem;
    font-size: 500;
    color: #444;
    margin-bottom: 2rem;
}
#thegeorgie .features-page h5 + h5{
margin-top: 3rem;
}
#thegeorgie .features-page ul{
    margin-bottom: 4rem;
}
#thegeorgie .features-page ul li{
    margin-bottom: 1rem;
    color: #444;
    font-size: 1.8rem;
}
#thegeorgie .features-page .accordion-header button{
  background: transparent !important;
  box-shadow: none !important;
  font-size: 1.8rem;
  padding: 1.5rem 2rem;
  color: #444;
  font-weight: 600;
}
#thegeorgie .features-page .accordion-header button:hover{
    color: #fa360a;
}
#thegeorgie .features-page .accordion-body p{
    font-size: 1.8rem;
    color: #fa360a;
    padding: 0 1rem;
}
#thegeorgie .features-page .outline_btn{
    color: #646464;
    font-size: 18px;
    font-weight: 600;
    border: 1px solid #dfdfdf;
    border-radius: 30px;
    padding: 12px 45px;
    transition: all 0.3s ease-in-out;
    position: relative;
    display: inline-block;
}
#thegeorgie .features-page .outline_btn svg{
    margin-left: 10px;
}
#thegeorgie .features-page .outline_btn:hover{
    background: #fa360a;
    color: var(--bs-white);
}
@media (min-width:1023px) {
    #thegeorgie.inner_header .navbar.navbar-expand-lg{
        background: var(--bs-white);
        border: none;
        }
        #thegeorgie.inner_header .navbar.navbar-expand-lg li a{
            color: var(--bs-black);
        }
        #thegeorgie.inner_header .navbar.navbar-expand-lg li a:hover{
            color: #ff5e17;
        }
        #thegeorgie.inner_header .mainheader.scrolled {
            position: sticky;
            top: 0;
            z-index: 9;
        }

        #thegeorgie.inner_header .mainheader.scrolled .navbar-expand-lg {
            background: #fa360a;
            border: none;

        }
        #thegeorgie.inner_header .mainheader.scrolled .navbar.navbar-expand-lg li a,
        #thegeorgie.inner_header .mainheader.scrolled .navbar.navbar-expand-lg li a:hover{
            color: var(--bs-white);
        }

}
@media (min-width: 1400px) {
    #thegeorgie .geo_footer_main {
        position: sticky;
        bottom: 0;
        top: 0;
        z-index: 0;
    }
}

@media (max-width:767px) {
    #thegeorgie .top-header {
        padding: 12px;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;
        background: var(--bs-white);
    }

    #thegeorgie header .navbar.navbar-expand-lg {
        border-bottom: 1px solid #e2e2e2;
        background: var(--bs-white);
    }

    #thegeorgie .header-btn {
        border-radius: 4px;
        padding: 10px 20px;
    }

    #thegeorgie .top-header ul {
        gap: 0.5rem;
    }

    #thegeorgie .top-header ul li:nth-child(1) a,
    #thegeorgie .top-header ul li:nth-child(2) a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #ebebeb;

        width: 40px;
        height: 40px;
        font-size: 20px;
        transition: all ease 0.4s;

    }

    #thegeorgie .top-header ul li:nth-child(1) a svg,
    #thegeorgie .top-header ul li:nth-child(2) a svg {
        color: #646464;
    }

    #thegeorgie header .navbar-expand-lg .navbar-collapse {
        transition: 0.4s;
        position: absolute;
        background: white;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 99;
    }

    #thegeorgie .vedio-section iframe {
        height: 220px !important;

    }

    #thegeorgie .mainheader {
        position: sticky;
        z-index: 99;
        top: 0;
    }

    #thegeorgie .img_sec1 {
        display: none;
    }

    #thegeorgie .navbar-toggler {
        filter: brightness(1) invert(1);
    }

    #thegeorgie header .navbar-expand-lg .navbar-nav .nav-link {
        display: block;
        position: relative;
        color: #14133b;
        font-size: 16px;
        font-weight: 600;
        background: #ffffff;
        padding-bottom: 7px;
        padding-top: 7px;
    }

    #thegeorgie .mainheader.scrolled .navbar-expand-lg {
        background: var(--bs-white);
    }

    #thegeorgie .geo_flex {
        flex-wrap: wrap;
        justify-content: center;
    }

    #thegeorgie .geo_flex p {
        width: 100%;
    }

    #thegeorgie .geo_map {
        margin-top: 4rem;
    }

    #thegeorgie .copyright-wrap,
    #thegeorgie .footer-menu {
        flex-wrap: wrap;
    }

    .activeMenu {
        text-decoration: underline  !important
      }
    #thegeorgie .footer-menu {
        gap: 1.5rem;
    }

    #thegeorgie .copyright {
        padding-top: 2rem;
    }

    #thegeorgie .geo_footer {
        padding: 60px 0 40px 0;
    }
    #thegeorgie .geo-banner{
        padding: 80px 0 80px ;
    }
    #thegeorgie .geo-banner .top_icon, #thegeorgie .geo-banner .bottom_icon{
        display: none;
    }
}